@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/alertifyjs/build/css/alertify.min.css";
@import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html {
  overflow-y: scroll;
  scrollbar-color: #06152b lightgray;
  scrollbar-width: thin;
}
.title-container {
  overflow-y: scroll;
  scrollbar-color: #06152b lightgray;
  scrollbar-width: thin;
}
.table-responsive {
  overflow-y: scroll;
  scrollbar-color: #06152b lightgray;
  scrollbar-width: thin;
}
select {
  overflow-y: scroll;
  scrollbar-color: #06152b lightgray;
  scrollbar-width: thin;
}
.red-color {
  color: #c70e0e;
}
td a {
  color: #00bff9;
}
canvas {
  border-radius: 10px;
  /* background: #122744 !important; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff52;
  box-shadow: inset 0 0 5px #ffffff52;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #06152b !important;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #06152b !important;
}

.tbl_head_stricky {
  position: sticky !important;
  top: 0px;
  /* background: #546074!important; */
  background: #06152b !important;
}
/* .tbl_head_stricky tr,th{
  border: 1px solid #06152b !important;
} */
.ag-theme-alpine .ag-root-wrapper {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  border-radius: 8px;
}
body .ui-table .ui-table-tbody > tr {
  transition: box-shadow 0.2s;
  background: var(--bg-color) !important;
  border-radius: 0px;
  color: var(--color);
  vertical-align: baseline;
  text-align: left;
}
body .ui-table .ui-table-tbody > tr > td {
  /* border: 1px solid #546074!important; */
  /* transition: box-shadow 0.2s; */
  /* background: #06152b !important; */
  border-radius: 0px;
  color: var(--color);
  vertical-align: middle !important;
  text-align: left;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  /* color: #6b6f82; */
  text-align: left;
  /* background-color: #f4f5fa; */
}

body {
  /* background-color: var(--bg-color); */
  border-color: #05152af5;
  background-color: #05152af5;

  --color: #fff;
  color: var(--text-color);
  --text-color: #222;
  --bg-color: #06152b;
}
body.dark-theme {
  --color: #222;
  color: var(--color);
  --show: none;
  --bg-color: white;
  /* background-color: var(--bg-color); */
  border-color: white;
  background-color: #f4f5fa;
}

::ng-deep .p-dialog {
  z-index: 9999 !important; /* Ensures it's above all elements */
}


body.none {
  --show: none !important;
  border-color: #05152af5;
  background-color: #05152af5;

  --color: #fff;
  color: var(--text-color);
  --text-color: #222;
  --bg-color: #06152b;
}
body.display {
  --show: block;
  border-color: #05152af5;
  background-color: #05152af5;

  --color: #fff;
  color: var(--text-color);
  --text-color: #222;
  --bg-color: #06152b;
}

@media (prefers-color-scheme: dark) {
  /* defaults to dark theme */
  body {
    color: #fff;
    border-color: #05152af5;
    background-color: #05152af5;
  }
  body.light-theme {
    border-color: #05152af5;
    background-color: #05152af5;
  }
}

body .ui-multiselect {
  width: 100% !important;
}
/* body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #0000ff;
  background-color: #ffffff;
  padding: 0.02rem;
  margin: 1px;
} */
body
  .ui-multiselect-panel
  .ui-multiselect-items
  .ui-multiselect-item.ui-state-highlight {
  color: #000;
  background-color: #ffffff;
  margin: 1px;
  padding: 0.429em 0.857em;
}
body .ui-accordion .ui-accordion-header {
  background-color: #0753f7;
  padding: 0;
  color: #596269;
  font-size: 13px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body
  .ui-accordion
  .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover
  a {
  background-color: #447efa;
  border: 1px solid #033bb3;
  color: #fcfafa;
}
body
  .ui-accordion
  .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled)
  a {
  background-color: #0051ff;
  border: 1px solid #0039b3;
  color: #fcfafa;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -4px;
  margin-left: -4px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0px;
}
.logo {
  height: 50px;
}
.navbar {
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
dropdown {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 14px !important;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.font-weight-600 {
  font-weight: 600;
}
.text-col1 {
  color: #62abfa !important;
}
.bg-yellow.bg-lighten-4 {
  background-color: #fff9c4 !important;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.35rem;
  background-color: #fff;
  background-clip: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
}

/* label {
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 600;
  font-size: 13px;
} */
.h5,
h5 {
  font-size: 1.07rem;
}
.bg-light-1 {
  background-color: #f4f4f4;
}
.pl-5px {
  padding-left: 5px;
}
.text-custom {
  color: #007ad9;
  font-size: 18px;
}
.mt-31px {
  margin-top: 31px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 3px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.font-10 {
  font-size: 10px;
}
/* .cell-span {
  background-color: #f9f9f9;
  border: 1px solid lightblue !important;
} */

.cell-span-error {
  background-color: #f74747 !important;
  border: 1px solid #c70e0e !important;
}
shadow {
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
}
.tbl_bg {
  background-color: var(--bg-color) !important;
  box-shadow: 0px 0px 5px 0px #000000;
  /* border: 1px solid lightgray; */
  height: 538px;
  border-radius: 10px;
}
.top-52px {
  position: relative;
  top: 52px;
  font-size: 14px;
}
.addedRow {
  background-color: #0753f7 !important;
  border: 1px solid lightblue !important;
}
.updatededRow {
  background-color: chartreuse !important;
  border: 1px solid lightblue !important;
}
.height_450px {
  height: 450px;
  background-color: #06152b !important;
  border-radius: 8px;
}
.deleteRow {
  background-color: crimson !important;
  border: 1px solid lightblue !important;
}

html,
body {
  height: 100%;
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.confirmation-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2px 7px;
  border-bottom: 1px solid #06152b;
  background-color: #06152b;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  box-shadow: 0px 0px 5px 0px #000000;
}

.confirmation-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.highcharts-credits {
  display: none;
}

/* no-gutters Class Rules */
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.no-gutters-1 {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters-1 > [class^="col-"],
.row.no-gutters-1 > [class*=" col-"] {
  padding-right: 1px;
  padding-left: 1px;
}

/* .btn-grey {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  border-color: #06152b ;
  background-color: #06152b ;
}

.btn-grey:hover {
  color: #fff;
} */

.btn-grey {
  color: #000;
  font-weight: 500;
  border-color: #5d8fe8a1;
  background-color: #abbddda1;
}
.badge-grey-custom {
  color: #fff;
  background-color: #8099c3;
}

/* .blue-theme-bg{
  background-color: #0DB1E4 !important;
} */
.blue-theme-text {
  color: #0db1e4;
}
.btn-custom {
  background: #0a94bb 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 2px #9f9f9f63;
  border-radius: 6px;
  opacity: 1;
  color: #fff !important;
  width: 150px;
}
.btn-cancel {
  box-shadow: 0px 3px 5px #00000029;
  opacity: 1;
  background: #fff;
  color: #000 !important;
  border-radius: 5px !important;
  border-radius: 4px;
}
::ng-deep.ui-table-wrapper {
  width: 100%;
  overflow: scroll;
}
::ng-deep body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--color);
  width: 150px;
  background-color: var(--bg-color);
}

.cursor-pointer {
  cursor: pointer;
}
.title-text {
  font-size: 20px;
  color: #fff !important ;
  letter-spacing: 0;
}
::ng-deep.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 8px) !important;
}
::ng-deep.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 8px) !important;
}
.user_table {
  background: #06152b;
  /* box-shadow: 0 1px 10px 2px #EAEAEA; */
  border-radius: 8px;
  color: #fff;
}
.cat-card-same-height {
  height: 430px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0px;
}
.table_xd {
  background-color: rgb(255 255 255 / 18%);
  color: #fff;
}
.text-danger {
  color: #b68589 !important;
}
.bg-custom {
  background: #06152b !important;
}
.btn-add-site-user {
  box-shadow: 0px 0px 5px 0px #000000;
  opacity: 1;
  background: #06152b;
  color: #fff;
  border-radius: 5px !important;
}
.btn-add-site-user:hover {
  color: #fff !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid rgb(255 255 255 / 21%);
}
::ng-deep.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0px 0px 0px 0px !important;
}

::ng-deep.blue-theme-bg {
  background: rgb(48 63 87 / 70%) !important;
}
.w_50px {
  width: 50px !important;
}
.w_150px {
  width: 150px !important;
}
.form-control:focus,
.btn:focus {
  box-shadow: none;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error),
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus {
  box-shadow: none;
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: transparent !important;
  color: #333333;
  border: 0px solid #c8c8c8 !important;
  padding: 0.571em 1em;
  text-align: center;
}
.ui-table table {
  border-collapse: collapse;
  width: 100% !important;
  table-layout: auto;
  display: inline-table;
  overflow-x: auto;
}
::ng-deep label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: rgb(66 67 69 / 50%);
  font-weight: normal;
  line-height: 20px;
}
.modal_popup {
  background-color: #06152b !important;
  color: #fff !important;
}

.cat-site-footer-height {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
::ng-deep .ag-theme-alpine .ag-cell {
  line-height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  background: #303f57 !important;
}
::ng-deep.ag-theme-alpine .ag-header-row {
  background: #4e6994;
  color: #fff !important;
}
::ng-deep.form-actions {
  background: #06152b;
  box-shadow: 0 1px 10px 2px #eaeaea;
  color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  width: 100%;
}
::ng-deep.ag-cell-inline-editing {
  color: #000 !important;
}
.ag-theme-alpine input[class^="ag-"]:not([type]),
.ag-theme-alpine input[class^="ag-"][type="text"],
.ag-theme-alpine input[class^="ag-"][type="number"],
.ag-theme-alpine input[class^="ag-"][type="tel"],
.ag-theme-alpine input[class^="ag-"][type="date"],
.ag-theme-alpine input[class^="ag-"][type="datetime-local"],
.ag-theme-alpine textarea[class^="ag-"] {
  font-size: inherit;
  line-height: inherit;
  color: #000 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #babfc7;
  border-color: var(--ag-input-border-color, var(--ag-border-color, #babfc7));
  background-color: #fff !important;
}
::ng-deep.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 18px;
  padding-right: 18px;
  color: #000 !important;
}
.ag-wrapper .ag-picker-field-wrapper {
  color: #000 !important;
}
.ag-theme-alpine .ag-select .ag-picker-field-wrapper {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  min-height: 30px;
  cursor: default;
  color: #000 !important;
}

.ag-theme-alpine .ag-row .ag-cell .cell-span-error {
  background-color: #f74747 !important;
  border: 1px solid #c70e0e !important;
}
.ag-theme-alpine .ag-cell {
  line-height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  /* background: #303F57; */
  color: #fff !important;
}
.ag-theme-alpine .ag-row {
  font-size: 14px;
  background: #303f57;
}
.configDiagram .cell-span {
  border: none !important;
}
/* .cell-span {
  background-color: #303F57!important;
  border: 1px solid lightblue ;
} */
.nav-pills .nav-link.active.nav-error-content {
  background-color: #ff3b19 !important;
}
.ag-header-group-cell-label,
.ag-header-cell-label {
  display: flex !important;
  justify-content: center !important;
}
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-alpine
  .ag-header-row:not(:first-child)
  .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: solid 1px;
  border-top-color: var(--ag-border-color, #babfc7);
  background: #546074 !important;
  color: #fff;
}
.ag-theme-alpine .ag-filter-toolpanel-header,
.ag-theme-alpine .ag-filter-toolpanel-search,
.ag-theme-alpine .ag-status-bar,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-panel-title-bar-title {
  font-weight: 700;
  background: #546074 !important;
  color: #fff !important;
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))
  );
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #303f57 !important;
  border: #303f57 !important;
}
.nav-pills .nav-link {
  color: #333333;
  background: rgb(191 195 205 / 58%);
  margin: 0px 2px;
  border-radius: 6px 6px 0px 0px !important;
}
.scroll_y {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.mb-160px {
  margin-bottom: 160px;
}

/* ::ng-deep.ag-theme-alpine .ag-header-row {
  background: #546074;
  color: #fff!important;
}
::ng-deep.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid #fff;
}
::ng-deep.nav-pills .nav-link {
  color: #333333;
  background: rgb(191 195 205 / 58%);
  margin: 0px 2px;
  border-radius: 6px 6px 0px 0px!important;
}
::ng-deep.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #303F57!important;
  border: #303F57!important;
}
::ng-deep.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0px 0px 0px 0px!important;
}
::ng-deep.ag-theme-alpine.ag-row.ag-cell.cell-span-error{
  background-color: #f74747!important;
    border: 1px solid #c70e0e !important;
} */
/* .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: red!important;
  border: #303F57!important;
} */
/* background-color: #f74747!important;
    border: 1px solid #c70e0e !important;
} */
/* Column Priorities */
@media only all {
  th.ui-p-6,
  td.ui-p-6,
  th.ui-p-5,
  td.ui-p-5,
  th.ui-p-4,
  td.ui-p-4,
  th.ui-p-3,
  td.ui-p-3,
  th.ui-p-2,
  td.ui-p-2,
  th.ui-p-1,
  td.ui-p-1 {
    display: none;
  }
}

/* Show priority 1 at 320px (20em x 16px) */
@media screen and (min-width: 20em) {
  th.ui-p-1,
  td.ui-p-1 {
    display: table-cell;
  }
}

/* Show priority 1 at 320px (20em x 16px) */
@media screen and (max-width: 712px) {
  .ui-table table {
    border-collapse: collapse;
    width: 100% !important;
    table-layout: fixed;
    display: -webkit-inline-box;
    overflow-x: auto;
  }
}
@media screen and (max-width: 992px) {
  .ui-table table {
    border-collapse: collapse;
    width: 100% !important;
    table-layout: fixed;
    display: -webkit-inline-box;
    overflow-x: auto;
  }
}

/* Show priority 2 at 480px (30em x 16px) */
@media screen and (min-width: 30em) {
  th.ui-p-2,
  td.ui-p-2 {
    display: table-cell;
  }
}

/* Show priority 3 at 640px (40em x 16px) */
@media screen and (min-width: 40em) {
  th.ui-p-3,
  td.ui-p-3 {
    display: table-cell;
  }
}

/* Show priority 4 at 800px (50em x 16px) */
@media screen and (min-width: 50em) {
  th.ui-p-4,
  td.ui-p-4 {
    display: table-cell;
  }
}

/* Show priority 5 at 960px (60em x 16px) */
@media screen and (min-width: 60em) {
  th.ui-p-5,
  td.ui-p-5 {
    display: table-cell;
  }
}

/* Show priority 6 at 1,120px (70em x 16px) */
@media screen and (min-width: 70em) {
  th.ui-p-6,
  td.ui-p-6 {
    display: table-cell;
  }
}

/* .md-drppicker.double {
  width: 90% !important;
} */

.mat-step-label {
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 600;
  color: #1d2fab !important;
}

.ui-breadcrumb ul {
  font-size: 12px;
}

.set-site.ag-theme-alpine .ag-header.ag-focus-managed.ag-pivot-off {
  display: none !important;
}

.set-site.ag-theme-alpine .ag-header {
  display: none !important;
}

.set-site.ag-theme-alpine .ag-header .ag-header-row {
  display: none !important;
}

.set-site.ag-theme-alpine .ag-cell {
  padding: 5px !important;
}

.set-site.ag-theme-alpine .ag-row {
  border-style: dashed;
  border-color: #babfc7 !important;
  background: #ffffff;
}
.set-site.ag-theme-alpine .ag-cell {
  border-right: 2px dashed !important;
  border-color: #babfc7 !important;
  border: none;
}

/* .cell-span {
  background-color: var(--bg-color)!important; 
  border-right: 2px dashed !important;
  border-color: #babfc7 !important;
} */

.ui-breadcrumb {
  border: none !important;
  padding: 0 !important;
}

.ui-table-scrollable-footer {
  display: none !important;
}

.ui-dialog .ui-dialog-content {
  height: 100%;
}

.configDiagram.ag-theme-alpine .ag-cell {
  border-right: 1px dashed !important;
  border-color: #dee0e3 !important;
  border: none;
}

.configDiagram.ag-theme-alpine .ag-row {
  border-style: dashed;
  border-color: #dee0e3 !important;
  background: #ffffff;
}

.configDiagram.ag-theme-alpine .ag-root-wrapper {
  border-color: #ededed !important;
  border: none !important;
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
span.highcharts-title {
  width: 100% !important;
}

/* .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item > .ui-button {
  border-color: white !important;
  border: 1px solid #fff !important;
} */

button:focus {
  outline: none !important;
}

.leader-line {
  display: var(--show) !important;
}

.ui-carousel-dots-container {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.ui-carousel-container {
  height: 80px !important;
}

.ui-carousel-content {
  overflow: hidden !important;
}

.reportcrousel .ui-carousel-item {
  display: contents !important;
}

.reportcrousel ul.ui-carousel-dots-container.ui-helper-reset {
  margin: 0 !important;
  display: none !important;
}

.reportcrousel button.ui-carousel-prev {
  margin: 0 10px !important;
  width: 2em !important;
  height: 2em !important;
  /* margin-bottom: 11px !important; */
}

.reportcrousel button.ui-carousel-next {
  margin: 0 10px !important;
  width: 2em !important;
  height: 2em !important;
  /* margin-bottom: 11px !important; */
}

.reportcrousel .ui-carousel-container {
  height: 48px !important;
  align-items: center !important;
}

.rangecheck .ui-chkbox .ui-chkbox-box {
  width: 18px;
  height: 18px;
}

.rangecheck .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  font-size: 14px !important;
}
.ui-carousel-dots-container {
  display: none !important;
}
/* canvas {
    border-radius:20px;
  } */
.chartDahboardBodyMin {
  height: 120px;
  width: 100% !important;
  border: 1px solid lightgrey;
  border-radius: 10px !important;
}
.modal-body {
  padding-bottom: 0px;
}
::ng-deep body .calendar-table table thead > tr > th {
  background-color: transparent !important;
}
::ng-deep body .calendar-table table tbody > tr > td {
  color: #333333 !important;
}
::ng-deep body .calendar-table table tbody > tr > td.active {
  color: #fff !important;
}
::ng-deep body .calendar-table table tbody > tr:nth-child(odd) {
  background-color: transparent !important;
}
::ng-deep body .calendar-table table tbody > tr:nth-child(even) {
  background-color: transparent !important;
}
body .ui-paginator {
  background-color: #122744;
  border: 0;
  padding: 0;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #000000;
}
body .ui-paginator .ui-dropdown {
  height: auto !important;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #06152b;
  color: #fff;
}
body .ui-paginator .ui-dropdown .ui-dropdown-label {
  color: #fff;
  background-color: #06152b !important;
  border-radius: 0px;
}
body .ui-paginator .ui-dropdown .ui-dropdown-trigger {
  color: #fff;
  background: #06152b !important;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  height: 2.399em;
}
.tbl-h {
  box-shadow: 0px 0px 5px 0px #000000;
}
.modal-content {
  box-shadow: 0px 0px 5px 0px #000000;
}
.report-detail-box {
  box-shadow: 0px 0px 5px 0px #000000;
  padding: 10px;
  border-radius: 10px;
}
.justify-content-space-between {
  justify-content: space-between;
}
/* ::ng-deep.ui-carousel-items-container {
    transform: none !important;
  } */
body .calendar-table table thead > tr > th {
  background-color: transparent !important;
}
body .calendar-table table tbody > tr > td {
  color: #333333 !important;
}
body .calendar-table table tbody > tr > td.active {
  color: #fff !important;
}
body .calendar-table table tbody > tr:nth-child(odd) {
  background-color: transparent !important;
}
body .calendar-table table tbody > tr:nth-child(even) {
  background-color: transparent !important;
}
.abcRioButtonLightBlue {
  margin: auto !important;
}
.custom-ag-theme .ag-center-cols-container {
  width: 100% !important;
}
.custom-ag-theme .ag-header {
  background: none !important;
  border: 0px !important;
}
.custom-ag-theme .ag-header-container {
  width: 100% !important;
}
.custom-ag-theme .ag-body-horizontal-scroll-container {
  width: 100% !important;
}
.custom-ag-theme .ag-header-row {
  width: 100% !important;
  background: #06152b !important;
}
.custom-ag-theme .ag-row {
  background: #122744 !important;
}
.custom-ag-theme .ag-header-cell-label {
  flex: initial;
}
.highlightExpireRow td {
  background: #671515;
  border-bottom: 1px solid #122744;
}

.cdk-overlay-container {
  z-index: 1056 !important;
}

.mat-tab-label{
  color: white !important;
}

.mat-tab-group-slideshow mat-tab-header{
  padding: 0 20px;
}
